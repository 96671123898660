// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bread_crumbs {
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 14px 0 16px 0;
}

.bread_crumbs .Service_text,
.bread_crumbs .Service_inner_step {
    font-size: 13px;
    line-height: 16px;
    text-transform: capitalize;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
    color: var(--primary-color);
}

.AdditionalDescription_otr .desc {
    color: var(--desc-color);
    font-size: 16px;
    line-height: 32px;
    font-weight: 400;
    font-family: 'Poppins', sans-serif !important;
    letter-spacing: 0.01071em;
    margin: 20px 0 32px 0;
}

.AdditionalDescription_otr .text_area {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    font-family: 'Poppins', sans-serif !important;
    letter-spacing: 0.00938em;
    color: var(--dark-text-color);
    padding: 16.5px 14px !important;
    height: 216px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    resize: none;
}

.AdditionalDescription_otr .text_area::placeholder {
    color: var(--black-opacity-60);
}`, "",{"version":3,"sources":["webpack://./src/Components/Description/AdditionalDescription/Description.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,QAAQ;IACR,sBAAsB;AAC1B;;AAEA;;IAEI,eAAe;IACf,iBAAiB;IACjB,0BAA0B;IAC1B,gBAAgB;IAChB,kCAAkC;IAClC,2BAA2B;AAC/B;;AAEA;IACI,wBAAwB;IACxB,eAAe;IACf,iBAAiB;IACjB,gBAAgB;IAChB,6CAA6C;IAC7C,yBAAyB;IACzB,qBAAqB;AACzB;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,6CAA6C;IAC7C,yBAAyB;IACzB,6BAA6B;IAC7B,+BAA+B;IAC/B,aAAa;IACb,kBAAkB;IAClB,0BAA0B;IAC1B,uBAAuB;IACvB,sBAAsB;IACtB,qBAAqB;IACrB,YAAY;AAChB;;AAEA;IACI,8BAA8B;AAClC","sourcesContent":[".bread_crumbs {\n    display: flex;\n    align-items: center;\n    gap: 6px;\n    padding: 14px 0 16px 0;\n}\n\n.bread_crumbs .Service_text,\n.bread_crumbs .Service_inner_step {\n    font-size: 13px;\n    line-height: 16px;\n    text-transform: capitalize;\n    font-weight: 400;\n    font-family: 'Poppins', sans-serif;\n    color: var(--primary-color);\n}\n\n.AdditionalDescription_otr .desc {\n    color: var(--desc-color);\n    font-size: 16px;\n    line-height: 32px;\n    font-weight: 400;\n    font-family: 'Poppins', sans-serif !important;\n    letter-spacing: 0.01071em;\n    margin: 20px 0 32px 0;\n}\n\n.AdditionalDescription_otr .text_area {\n    font-weight: 400;\n    font-size: 16px;\n    line-height: 24px;\n    font-family: 'Poppins', sans-serif !important;\n    letter-spacing: 0.00938em;\n    color: var(--dark-text-color);\n    padding: 16.5px 14px !important;\n    height: 216px;\n    border-radius: 8px;\n    -webkit-border-radius: 8px;\n    -moz-border-radius: 8px;\n    -ms-border-radius: 8px;\n    -o-border-radius: 8px;\n    resize: none;\n}\n\n.AdditionalDescription_otr .text_area::placeholder {\n    color: var(--black-opacity-60);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
