import React, { useContext, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./AppointmentCalendar.css";
import { AppContext } from "../../Context/AppContext";


function AppointmentDatePicker({
	calendarData,
	onSelectedDate,
	setSelectedTimeSlot,
	timeSlotRef,
}) {
	const [startDate, setStartDate] = useState(new Date());
	const { state, updateState } = useContext(AppContext);

	useEffect(() => {
		setSelectedTimeSlot(null);
		const localDate = new Date();
		localDate.setMinutes(
			localDate.getMinutes() - localDate.getTimezoneOffset()
		);

		const formattedDateUTC = localDate.toISOString().split("T")[0];
		const selectedAppointment = calendarData?.[formattedDateUTC];
		onSelectedDate(selectedAppointment);

		updateState({
			...state,
			appointment: {
				...state.appointment,
				date: formattedDateUTC,
				displayStartTime: null,
				displayEndTime: null,
				arrivalWindowStart: null,
				arrivalWindowEnd: null,
			},
		});
	}, [calendarData]);

	const handleChange = (date) => {
		setStartDate(date);
		const localDate = new Date(date);
		localDate.setMinutes(
			localDate.getMinutes() - localDate.getTimezoneOffset()
		);
		const formattedDateUTC = localDate.toISOString().split("T")[0];

		const selectedAppointment = calendarData?.[formattedDateUTC];
		onSelectedDate(selectedAppointment);

		setSelectedTimeSlot(null);

		updateState({
			...state,
			isSubStepValid: false,
			appointment: {
				...state.appointment,
				date: formattedDateUTC,
				displayStartTime: null,
				displayEndTime: null,
				arrivalWindowStart: null,
				arrivalWindowEnd: null,
			},
		});
	};

	const minDate = new Date();
	const maxDate = new Date();
	maxDate.setMonth(maxDate.getMonth() + 4);
	return (
		<div className="DatePickerComponent_otr">
			<DatePicker
				selected={startDate}
        minDate={minDate}
        maxDate={maxDate}
				onChange={(e) => {
					handleChange(e);
					timeSlotRef.current?.scrollIntoView({
						behavior: "smooth",
					});
				}}
				inline
			/>
		</div>
	);
}

export default AppointmentDatePicker;
