// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.QuestionsList_otr .heading_icon_otr {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.question_list_inr {
	padding: 16px 0;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	gap: 0.625rem;
	width: -moz-fit-content;
	width: fit-content;
}
.questions_button {
	cursor: pointer;
	border: 1px solid var(--problem-card-border-color);
	padding: 4px 13px;
	background: var(--white-color);
	border-radius: 0.5rem;
	-webkit-border-radius: 0.5rem;
	-moz-border-radius: 0.5rem;
	-ms-border-radius: 0.5rem;
	-o-border-radius: 0.5rem;
}

.questions_button.clicked {
	border: 1px solid var(--primary-color);
	background-color: var(--primary-light);
}

.questions_button:hover {
	background-color: var(--primary-light);
}

.questions_button .button_text {
	font-size: 16px;
	font-weight: 400;
	line-height: 32px;
	font-family: Verdana !important;
	color: var(--desc-color2);
}

.questions_button.clicked .button_text {
	color: var(--primary-color) !important;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Description/Questions/Questions.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,mBAAmB;CACnB,8BAA8B;AAC/B;;AAEA;CACC,eAAe;CACf,aAAa;CACb,mBAAmB;CACnB,eAAe;CACf,aAAa;CACb,uBAAkB;CAAlB,kBAAkB;AACnB;AACA;CACC,eAAe;CACf,kDAAkD;CAClD,iBAAiB;CACjB,8BAA8B;CAC9B,qBAAqB;CACrB,6BAA6B;CAC7B,0BAA0B;CAC1B,yBAAyB;CACzB,wBAAwB;AACzB;;AAEA;CACC,sCAAsC;CACtC,sCAAsC;AACvC;;AAEA;CACC,sCAAsC;AACvC;;AAEA;CACC,eAAe;CACf,gBAAgB;CAChB,iBAAiB;CACjB,+BAA+B;CAC/B,yBAAyB;AAC1B;;AAEA;CACC,sCAAsC;AACvC","sourcesContent":[".QuestionsList_otr .heading_icon_otr {\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: space-between;\n}\n\n.question_list_inr {\n\tpadding: 16px 0;\n\tdisplay: flex;\n\tflex-direction: row;\n\tflex-wrap: wrap;\n\tgap: 0.625rem;\n\twidth: fit-content;\n}\n.questions_button {\n\tcursor: pointer;\n\tborder: 1px solid var(--problem-card-border-color);\n\tpadding: 4px 13px;\n\tbackground: var(--white-color);\n\tborder-radius: 0.5rem;\n\t-webkit-border-radius: 0.5rem;\n\t-moz-border-radius: 0.5rem;\n\t-ms-border-radius: 0.5rem;\n\t-o-border-radius: 0.5rem;\n}\n\n.questions_button.clicked {\n\tborder: 1px solid var(--primary-color);\n\tbackground-color: var(--primary-light);\n}\n\n.questions_button:hover {\n\tbackground-color: var(--primary-light);\n}\n\n.questions_button .button_text {\n\tfont-size: 16px;\n\tfont-weight: 400;\n\tline-height: 32px;\n\tfont-family: Verdana !important;\n\tcolor: var(--desc-color2);\n}\n\n.questions_button.clicked .button_text {\n\tcolor: var(--primary-color) !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
