import { AirQuality, ElectricalInstallation, HvacInstallation, HvacRepair, PlumbingInstallation, Maintenance } from "./icons";

export const SchedulerSteps = [
	{ title: "Client Info" },
	{ title: "Problem" },
	{ title: "Description" },
	{ title: "Appointment" },
	{ title: "Confirm" },
];

export const paramSchedulerSteps=[
	{ title: "Client Info" },
	{ title: "Description" },
	{ title: "Appointment" },
	{ title: "Confirm" },
];

export const icons = {
	// trades
	plumbing: AirQuality,
	electrical: ElectricalInstallation,
	hvac: HvacInstallation,
	"heating & cooling": HvacInstallation,
	
 	// service types
	repair: HvacRepair,
	estimate: PlumbingInstallation,
	maintenance: Maintenance,
}

export const flagConfig = {
	'new-existing-customer': {
	  disabled: false,
	  variants: {
		on: true,
		off: false,
	  },
	  defaultVariant: "off",
	  contextEvaluator: (context) => {
		if (1) {
		  return 'on';
		}
		return 'off';
	  }
	},
  };

