// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./Verdana-BoldItalic.eot", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./Verdana-BoldItalic.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./Verdana-BoldItalic.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./Verdana-BoldItalic.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("./Verdana-Italic.eot", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("./Verdana-Italic.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_6___ = new URL("./Verdana-Italic.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_7___ = new URL("./Verdana-Italic.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_8___ = new URL("./Verdana.eot", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_9___ = new URL("./Verdana.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_10___ = new URL("./Verdana.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_11___ = new URL("./Verdana.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_12___ = new URL("./Verdana-Bold.eot", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_13___ = new URL("./Verdana-Bold.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_14___ = new URL("./Verdana-Bold.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_15___ = new URL("./Verdana-Bold.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___, { hash: "?#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___, { hash: "?#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_8___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_9___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
var ___CSS_LOADER_URL_REPLACEMENT_10___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_8___);
var ___CSS_LOADER_URL_REPLACEMENT_11___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_8___, { hash: "?#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_12___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_9___);
var ___CSS_LOADER_URL_REPLACEMENT_13___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_10___);
var ___CSS_LOADER_URL_REPLACEMENT_14___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_11___);
var ___CSS_LOADER_URL_REPLACEMENT_15___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_12___);
var ___CSS_LOADER_URL_REPLACEMENT_16___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_12___, { hash: "?#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_17___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_13___);
var ___CSS_LOADER_URL_REPLACEMENT_18___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_14___);
var ___CSS_LOADER_URL_REPLACEMENT_19___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_15___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family: 'Verdana-BoldItalic';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    src: local('./Verdana Bold Italic'), local('./Verdana-BoldItalic'),
        url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('embedded-opentype'),
        url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('woff2'),
        url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format('woff'),
        url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format('truetype');
}

@font-face {
    font-family: 'Verdana';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_5___});
    src: local('./Verdana Italic'), local('./Verdana-Italic'),
        url(${___CSS_LOADER_URL_REPLACEMENT_6___}) format('embedded-opentype'),
        url(${___CSS_LOADER_URL_REPLACEMENT_7___}) format('woff2'),
        url(${___CSS_LOADER_URL_REPLACEMENT_8___}) format('woff'),
        url(${___CSS_LOADER_URL_REPLACEMENT_9___}) format('truetype');
}

@font-face {
    font-family: 'Verdana';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_10___});
    src: local('./Verdana'),
        url(${___CSS_LOADER_URL_REPLACEMENT_11___}) format('embedded-opentype'),
        url(${___CSS_LOADER_URL_REPLACEMENT_12___}) format('woff2'),
        url(${___CSS_LOADER_URL_REPLACEMENT_13___}) format('woff'),
        url(${___CSS_LOADER_URL_REPLACEMENT_14___}) format('truetype');
}

@font-face {
    font-family: 'Verdana-Bold';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_15___});
    src: local('./Verdana Bold'), local('./Verdana-Bold'),
        url(${___CSS_LOADER_URL_REPLACEMENT_16___}) format('embedded-opentype'),
        url(${___CSS_LOADER_URL_REPLACEMENT_17___}) format('woff2'),
        url(${___CSS_LOADER_URL_REPLACEMENT_18___}) format('woff'),
        url(${___CSS_LOADER_URL_REPLACEMENT_19___}) format('truetype');
}`, "",{"version":3,"sources":["webpack://./src/fonts/fonts.css"],"names":[],"mappings":"AAAA;IACI,iCAAiC;IACjC,4CAAoC;IACpC;;;;kEAIsD;AAC1D;;AAEA;IACI,sBAAsB;IACtB,4CAAgC;IAChC;;;;kEAIkD;AACtD;;AAEA;IACI,sBAAsB;IACtB,6CAAyB;IACzB;;;;mEAI2C;AAC/C;;AAEA;IACI,2BAA2B;IAC3B,6CAA8B;IAC9B;;;;mEAIgD;AACpD","sourcesContent":["@font-face {\n    font-family: 'Verdana-BoldItalic';\n    src: url('./Verdana-BoldItalic.eot');\n    src: local('./Verdana Bold Italic'), local('./Verdana-BoldItalic'),\n        url('./Verdana-BoldItalic.eot?#iefix') format('embedded-opentype'),\n        url('./Verdana-BoldItalic.woff2') format('woff2'),\n        url('./Verdana-BoldItalic.woff') format('woff'),\n        url('./Verdana-BoldItalic.ttf') format('truetype');\n}\n\n@font-face {\n    font-family: 'Verdana';\n    src: url('./Verdana-Italic.eot');\n    src: local('./Verdana Italic'), local('./Verdana-Italic'),\n        url('./Verdana-Italic.eot?#iefix') format('embedded-opentype'),\n        url('./Verdana-Italic.woff2') format('woff2'),\n        url('./Verdana-Italic.woff') format('woff'),\n        url('./Verdana-Italic.ttf') format('truetype');\n}\n\n@font-face {\n    font-family: 'Verdana';\n    src: url('./Verdana.eot');\n    src: local('./Verdana'),\n        url('./Verdana.eot?#iefix') format('embedded-opentype'),\n        url('./Verdana.woff2') format('woff2'),\n        url('./Verdana.woff') format('woff'),\n        url('./Verdana.ttf') format('truetype');\n}\n\n@font-face {\n    font-family: 'Verdana-Bold';\n    src: url('./Verdana-Bold.eot');\n    src: local('./Verdana Bold'), local('./Verdana-Bold'),\n        url('./Verdana-Bold.eot?#iefix') format('embedded-opentype'),\n        url('./Verdana-Bold.woff2') format('woff2'),\n        url('./Verdana-Bold.woff') format('woff'),\n        url('./Verdana-Bold.ttf') format('truetype');\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
