import React, { useContext } from "react";
import { AppContext } from "../../Context/AppContext";
import Header from "./Header";
import AppointmentInfo from "./AppointmentInfo";
import ActionButtons from "./ActionButtons";
import "./AppointmentReview.css";
import { useBookings } from "../../hooks/bookings";

const AppointmentCreated = ({ onClose }) => {
  const { state, updateState } = useContext(AppContext);
  const {loading,hasError} = useBookings({state,updateState});

  return (
    <form className="steps_content_inside AppointmentCreated">
      <Header hasError={hasError} />
      {!hasError ? (
        <>
          <AppointmentInfo
            appointment={state?.appointment}
            clientInfo={state?.clientInfo}
          />
          <ActionButtons
            loading={loading}
				state={state}
            onClose={onClose}
            hasError={hasError}
          />
        </>
      ) : (
        <>
          <ActionButtons
            loading={loading}
				state={state}
            onClose={onClose}
            hasError={hasError}
          />
        </>
      )}
    </form>
  );
};

export default AppointmentCreated;
