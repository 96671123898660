import React from "react";

const Header = ({ hasError }) => (
  !hasError && (
    <h6 className="header_heading">
    {"Thank you, appointment created"}
    <hr />
    <br/>
  </h6>
  )
);

export default Header; 