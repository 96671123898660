import { useState, useContext, useEffect } from "react";
import { WarningOutlined } from "@ant-design/icons";
import { AppContext } from "../../../Context/AppContext.js";
import { PrimaryInput } from "../../General/Input/index.tsx";
import { LoadingSpinner } from "../../General/Spinner/index.tsx";
import { getCustomer } from "../../../apis/customer";
import "./ExistingCustomer.css";
import { ValidCustomer } from "../../../Utils/icons.js";

const ExistingCustomer = () => {
	const { state, updateState } = useContext(AppContext);


	const [phoneNumber, setPhoneNumber] = useState("");
	const [loading, setLoading] = useState(false);
	const [statusIcon, setStatusIcon] = useState(null);
	const [error, setError] = useState("");

	const handlePhoneNumberChange = (value) => {
		const numericValue = value.replace(/\D/g, "");
		const formattedValue = numericValue
			.replace(/(\d{3})(\d{3})?(\d{0,4})?/, (_, p1, p2, p3) => {
				let result = p1;
				if (p2) result += " " + p2;
				if (p3) result += " " + p3;
				return result;
			})
			.slice(0, 12);

		setPhoneNumber(formattedValue);

		if (numericValue.length === 10) {
			handleCustomerValidation(value);
		}
	};

	const handleCustomerValidation = async (phoneNumber) => {
		const payload = {
			phone: phoneNumber?.replace(/\s/g, ""),
			organizationId: state.organizationDetails?.id,
			event_id: state.event_id,
			cookie: state.cookie,
		};

		try {
			setLoading(true);
			updateState({
				...state,
				isSubStepValid: false,
				existingCustomerDetails: null,
				miscellaneous: {
					...state?.miscellaneous,
					loading: true,
				},
			});

			let res = await getCustomer(payload);
			if (res?.rows?.length > 0) {
				updateState({
					...state,
					isSubStepValid: true,
					existingCustomerDetails: res?.rows,
					clientInfo: {
						...state.clientInfo,
						...res.lead.leadData,
						mobilephone: phoneNumber?.replace(/\s/g, ""),
						followupleadid: res?.leadId,
					},
				});
				setError(false);
				setStatusIcon(ValidCustomer);
			} else {
				updateState({
					...state,
					isSubStepValid: false,
					miscellaneous: {
						...state.miscellaneous,
						loading: false,
					},
				});
				setError(true);
				setStatusIcon(<WarningOutlined style={{ color: "#F84771" }} />);
			}
		} catch (err) {
			setStatusIcon(<WarningOutlined style={{ color: "#F84771" }} />);
			setError(true);
			updateState({
				...state,
				isSubStepValid: false,
				miscellaneous: {
					...state.miscellaneous,
					loading: false,
				},
			});
		} finally {
			setLoading(false);
		}
	};

	const switchToNewCustomer = () => {
		updateState({
			...state,
			clientInfo: {
				...state.clientInfo,
				isExistingCustomer: false,
			},
		});
	};

	useEffect(() => {
		updateState({
			...state,
			isSubStepValid: false,
		});

		if (state?.clientInfo?.mobilephone) {
			const formattedValue = state?.clientInfo.mobilephone
				.replace(/(\d{3})(\d{3})?(\d{0,4})?/, (_, p1, p2, p3) => {
					let result = p1;
					if (p2) result += " " + p2;
					if (p3) result += " " + p3;
					return result;
				})
				.slice(0, 12);

			setPhoneNumber(formattedValue);
			setStatusIcon(ValidCustomer);
			updateState({
				...state,
				isSubStepValid: true,
			});
		}
	}, []);

	return (
		<>
			<div className="steps_content_inr CustomerValidation_otr">
				<h6 className="customer_heading">
					Existing Customer
					<hr />
				</h6>
				<p className="customer_desc">Please enter your account phone number</p>
				<PrimaryInput
					className="theme_input"
					labelText="Account phone number"
					placeholder="Account phone number"
					prefix={
						<svg
							width="20"
							height="21"
							viewBox="0 0 20 21"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M19.2578 14.25L15 12.4141C14.8828 12.375 14.6484 12.3359 14.5312 12.3359C14.2188 12.3359 13.7891 12.5312 13.5938 12.7656L11.875 14.875C9.57031 13.7422 6.75781 10.9688 5.625 8.625L7.73438 6.90625C7.96875 6.71094 8.16406 6.28125 8.16406 5.96875C8.16406 5.85156 8.125 5.61719 8.08594 5.5L6.25 1.24219C6.09375 0.851562 5.58594 0.539062 5.15625 0.539062C5.07812 0.539062 4.92188 0.539062 4.88281 0.539062L0.9375 1.47656C0.390625 1.59375 0 2.10156 0 2.64844C0 12.5312 8.00781 20.5 17.8516 20.5C18.3984 20.5 18.9062 20.1094 19.0625 19.5625L19.9609 15.6172C19.9609 15.5781 19.9609 15.4219 19.9609 15.3438C19.9609 14.9141 19.6484 14.4062 19.2578 14.25ZM17.8125 19.25C8.67188 19.25 1.25 11.8281 1.25 2.6875L5.11719 1.78906L6.91406 5.96875L4.0625 8.3125C5.97656 12.3359 8.16406 14.5234 12.2266 16.4375L14.5312 13.5859L18.7109 15.3828L17.8125 19.25Z"
								fill="#1F2327"
							></path>
						</svg>
					}
					suffix={loading ? <LoadingSpinner /> : statusIcon}
					value={phoneNumber}
					onChange={handlePhoneNumberChange}
				/>
				{error && (
					<p className="error_note">
						We couldn’t find this phone number in our database! If you’re not a
						customer yet, please{" "}
						<span className="error_text" onClick={switchToNewCustomer}>
							click here to enter your details.
						</span>
					</p>
				)}
				<p className="customer_note">
					Please Note:
					<span className="note_text">
						We may contact you by phone, email, or SMS about this visit.
					</span>
				</p>
			</div>
		</>
	);
};

export default ExistingCustomer;
