// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProblemList_otr .heading_icon_otr {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.ProblemList_otr .icon_otr {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 44px;
	height: 44px;
}

.ProblemList_otr .icon_otr svg {
	width: 40px;
	height: 40px;
}

.problem_list_inr {
	padding: 16px 0;
}
.arrow_button {
	display: flex;
	align-items: center;
	text-align: left;
	justify-content: space-between;
	cursor: pointer;
	border: 1px solid var(--problem-card-border-color);
	padding: 8px 16px;
	width: 100%;
	margin-bottom: 10px;
	background: var(--problem-button-bg);
	border-radius: 0.5rem;
	-webkit-border-radius: 0.5rem;
	-moz-border-radius: 0.5rem;
	-ms-border-radius: 0.5rem;
	-o-border-radius: 0.5rem;
}

.arrow_button:hover,
.arrow_button.clicked {
	border: 1px solid var(--desc-color) !important;
}

.arrow_button .button_text {
	font-size: 16px;
	font-weight: 400;
	line-height: 32px;
	font-family: Verdana !important;
	color: var(--black-color);
}

.arrow_button svg {
	width: 12px;
	height: 12px;
	margin-left: 15px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Problem/ProblemList/ProblemList.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,mBAAmB;CACnB,8BAA8B;AAC/B;AACA;CACC,aAAa;CACb,mBAAmB;CACnB,uBAAuB;CACvB,WAAW;CACX,YAAY;AACb;;AAEA;CACC,WAAW;CACX,YAAY;AACb;;AAEA;CACC,eAAe;AAChB;AACA;CACC,aAAa;CACb,mBAAmB;CACnB,gBAAgB;CAChB,8BAA8B;CAC9B,eAAe;CACf,kDAAkD;CAClD,iBAAiB;CACjB,WAAW;CACX,mBAAmB;CACnB,oCAAoC;CACpC,qBAAqB;CACrB,6BAA6B;CAC7B,0BAA0B;CAC1B,yBAAyB;CACzB,wBAAwB;AACzB;;AAEA;;CAEC,8CAA8C;AAC/C;;AAEA;CACC,eAAe;CACf,gBAAgB;CAChB,iBAAiB;CACjB,+BAA+B;CAC/B,yBAAyB;AAC1B;;AAEA;CACC,WAAW;CACX,YAAY;CACZ,iBAAiB;AAClB","sourcesContent":[".ProblemList_otr .heading_icon_otr {\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: space-between;\n}\n.ProblemList_otr .icon_otr {\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: center;\n\twidth: 44px;\n\theight: 44px;\n}\n\n.ProblemList_otr .icon_otr svg {\n\twidth: 40px;\n\theight: 40px;\n}\n\n.problem_list_inr {\n\tpadding: 16px 0;\n}\n.arrow_button {\n\tdisplay: flex;\n\talign-items: center;\n\ttext-align: left;\n\tjustify-content: space-between;\n\tcursor: pointer;\n\tborder: 1px solid var(--problem-card-border-color);\n\tpadding: 8px 16px;\n\twidth: 100%;\n\tmargin-bottom: 10px;\n\tbackground: var(--problem-button-bg);\n\tborder-radius: 0.5rem;\n\t-webkit-border-radius: 0.5rem;\n\t-moz-border-radius: 0.5rem;\n\t-ms-border-radius: 0.5rem;\n\t-o-border-radius: 0.5rem;\n}\n\n.arrow_button:hover,\n.arrow_button.clicked {\n\tborder: 1px solid var(--desc-color) !important;\n}\n\n.arrow_button .button_text {\n\tfont-size: 16px;\n\tfont-weight: 400;\n\tline-height: 32px;\n\tfont-family: Verdana !important;\n\tcolor: var(--black-color);\n}\n\n.arrow_button svg {\n\twidth: 12px;\n\theight: 12px;\n\tmargin-left: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
