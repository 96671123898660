import TradesComponent from "./Trade";
import ServiceTypesComponent from "./ServiceType";
import ProblemList from "./ProblemList";
import { useEffect } from "react";

const Problem = ({
	currentSubStep,
	setTotalSteps,
	setCurrentMainStep,
	setCurrentSubStep,
}) => {
	const InternalComponents = [
		{
			component: <TradesComponent setCurrentSubStep={setCurrentSubStep} />,
		},
		{
			component: (
				<ServiceTypesComponent setCurrentSubStep={setCurrentSubStep} />
			),
		},
		{
			component: (
				<ProblemList
					setCurrentMainStep={setCurrentMainStep}
					setCurrentSubStep={setCurrentSubStep}
				/>
			),
		},
	];

	useEffect(() => {
		const totalSteps = InternalComponents?.length;
		setTotalSteps((prev) => {
			return {
				...prev,
				problem: { ...prev?.problem, totalSteps: totalSteps - 1 },
			};
		});
	}, []);

	return (
		<div className="steps_content_inr">
			{InternalComponents[currentSubStep]?.component}
		</div>
	);
};

export default Problem;
