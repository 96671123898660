import React, { useEffect, useState } from "react";
import { Select } from "antd";
import { stateNames } from "../../../Utils/stateNames";

const onSearch = (value) => {
	console.log("search:", value);
};

// Filter `option.label` match the user type `input`
const filterOption = (input, option) =>
	(option?.label ?? "").toLowerCase().includes(input.toLowerCase());

function StateSelect({ onChange, value }) {
	const [selectedValue, setSelectedValue] = useState(null);
	const [isRequired, setRequired] = useState(false);

	const onSelectChange = (selectedState) => {
		// Call the parent component's onChange function

		setSelectedValue(selectedState);
		onChange(selectedState);
	};

	const handleBlur = () => {
		setRequired(!selectedValue);
	};

	useEffect(() => {
		onSelectChange(value);
	}, [value]);

	return (
		<div className="StateSelect_otr">
			<Select
				showSearch
				status={isRequired ? "error" : ""}
				placeholder="State"
				value={value}
				optionFilterProp="children"
				onBlur={handleBlur}
				onChange={onSelectChange}
				onSearch={onSearch}
				filterOption={filterOption}
				options={stateNames}
			/>
			{isRequired ? <p className="error_message">State is required</p> : ""}
		</div>
	);
}

export default StateSelect;
