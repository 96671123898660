import React from "react";
import { Typography } from "antd";
import './ErrorAlert.css';

const { Text } = Typography;

const CustomErrorAlert = () => (
  <div className="custom-error-alert">
    <img src={require('../../assets/images/warning.png')} style={{width: '30px', height: '30px'}} alt="error" className="custom-error-icon"/>
    <div className="custom-error-content">
      <Text className="custom-error-title">Unable to create booking</Text>
      <Text className="custom-error-description">
        Due to some technical problems, your appointment could not be created. We apologize for the inconvenience caused!
      </Text>
      <Text className="custom-error-action">
        Please <strong>call us</strong> and we will be happy to assist you.
      </Text>
    </div>
  </div>
);

export default CustomErrorAlert;
