import axios from "./axios";

export const createBooking = async (bookingPayload) => {
    try {

        const { data } = await axios.post(`/scheduler/create/jobRequest`,  bookingPayload  );
        return data || null;
    }
		catch (e) {
			return e;
		}
};
