import React, { ReactNode } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin, SpinProps } from 'antd';

interface LoadingSpinnerProps extends SpinProps {
  children?: ReactNode;
}

export const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({ children, ...spinProps }) => (
  <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} {...spinProps}>
    {children}
  </Spin>
);

