import axios from "./axios";

export const getTimeSlots = async (timeslotPayload, signal) => {
	try {
		const { data } = await axios.post(
			`/scheduler/get/timeSlots`,
			timeslotPayload,
			{
				timeout: 40000, // Timeout in milliseconds (40 seconds)
				signal, // Abort signal to cancel the request
			}
		);
		return data || "";
	} catch (err) {
		if (axios.isCancel(err)) {
			console.log("Request canceled:", err.message);
		} else {
			console.log(err);
		}
	}
};
