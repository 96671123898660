export const getJobQuestions = (state, selectedJob) => {
  const selectedTrade = state?.problem?.trade?.trade?.name;
  const selectedServiceType = state?.problem?.servicetype;

  let jobQuestions = [];
  
  state?.organizationDetails?.questions?.forEach((question) => {
    question?.associateJobs.forEach((associatedJob) => {
      const { trade, serviceType, job } = associatedJob;

      const serviceTypeLower = serviceType.map((s) => s?.toLowerCase());

      if (
        trade?.tradeName?.toLowerCase() === selectedTrade?.toLowerCase() &&
        serviceTypeLower?.includes(selectedServiceType) &&
        job.toLowerCase() === selectedJob.toLowerCase()
      ) {
        jobQuestions.push(question);
      }
    });
  });

  return jobQuestions;
};
