import { useFlag } from "@openfeature/react-sdk";
import { useEffect, useState } from "react";
import { createBooking } from "../../apis/booking";
import {
  createCustomer,
  createCustomerwhatconverts,
} from "../../apis/customer";

export const useBookings = ({
  state,
  updateState,
  currentMainStep,
  currentSubStep,
  setCurrentMainStep,
  setCurrentSubStep,
}) => {
  const followupflags = useFlag("new-existing-customer", false);
  const [loading, setloading] = useState(false);
  const [hasError, setError] = useState(Boolean(state?.miscellaneous?.error));

  useEffect(() => {
    setError(Boolean(state?.miscellaneous?.error));
  }, [state?.miscellaneous?.error]);

  const handleNewCustomerWhatconverts = async (customerDetails) => {
    try {
      setloading(true);
      const payload = {
        ...customerDetails,
        phone: customerDetails?.phone?.replace(/\s/g, ""),
        event_id: state.event_id,
        cookie: state.cookie || "",
        organizationId: state.organizationDetails?.id,
      };
      let res = await createCustomerwhatconverts(payload);
      updateState({
        ...state,
        miscellaneous: {
          ...state?.miscellaneous,
          newCustomerPagewhatconverts: false,
          newCustomerPage: false,
          error: "",
          loading: false,
        },
        clientInfo: {
          ...state.clientInfo,
          isExistingCustomer: false,
          followupleadid: res?.leadId,
        },
      });
    } catch (err) {
      throw err;
    }
  };

  const handleNewCustomerCall = async (customerDetails) => {
    try {
      setloading(true);

      const payload = {
        ...customerDetails,
        phone: customerDetails?.phone?.replace(/\s/g, ""),
        event_id: state.event_id,
        cookie: state.cookie || "",
        organizationId: state.organizationDetails?.id,
      };
      let res = await createCustomer(payload);
      if (followupflags?.value === false) {
        updateState({
          ...state,
          miscellaneous: {
            ...state?.miscellaneous,
            newCustomerPagewhatconverts: false,
            newCustomerPage: false,
            error: "",
          },
          clientInfo: {
            ...state.clientInfo,
            isExistingCustomer: false,
            mobilephone: payload?.phone,
            address: {
              location_id: res?.userData?.locations[0]?.id,
              address_street: res?.userData?.address?.street,
              address_state: res?.userData?.address?.state,
              address_city: res?.userData?.address?.city,
              address_country: res?.userData?.address?.country,
              address_zip: res?.userData?.address?.zip,
            },
            customer_id: res?.userData?.id,
            customer_name: res?.userData?.name,
            email: payload?.email,
          },
        });
      }
      return res;
    } catch (err) {
      throw err;
    }
  };

  const handleConfirmBooking = async () => {
    try {
      setloading(true);
      updateState({
        ...state,
        miscellaneous: {
          ...state.miscellaneous,
          loading: true,
        },
      });

      const jobItem = state?.additionalInfo?.jobItemToBook;

      const isMember = state?.clientInfo?.is_member;
      let formattedNotes = "";
      if (!state?.customUrlData) {
        formattedNotes = "User Path: ";
        formattedNotes += state?.problem?.trade?.trade?.name
          ? `${state?.problem?.trade?.trade?.name}`
          : "";
        formattedNotes += state?.problem?.servicetype
          ? ` > ${
              state?.problem?.servicetype.charAt(0).toUpperCase() +
              state?.problem?.servicetype.slice(1)
            }`
          : "";
        formattedNotes += state?.additionalInfo?.jobItemToBook?.jobItem?.jobItem
          ? ` > ${state?.additionalInfo?.jobItemToBook?.jobItem?.jobItem}\n\n`
          : "\n\n";
        formattedNotes += `Heading: ${state?.problem?.jobItem?.heading}\nJob Name: ${state?.additionalInfo?.jobItemToBook?.jobItem?.jobItem}\n\n`;
        if (state?.problem?.questions) {
          const questionAnswers = Object.entries(state?.problem?.questions)
            .map(([key, value]) => `Question: ${key}\nAnswer: ${value}`)
            .join("\n\n");

          formattedNotes += `Informational Questions:\n${questionAnswers}\n`;
        }
      }

      const customUrlData = state?.customUrlData;
      if (customUrlData) {
        let customUrlDataPayload = {
          organizationId: state.organizationDetails?.id,
          cookie: state.cookie,
          eventId: state.event_id,
          customer: {
            ...state?.clientInfo,
            is_member: isMember,
          },
          businessUnit: {
            id: state.customUrlData.buId.id,
            name: state.customUrlData.buId.name,
          },
          jobType: {
            is_member:
              customUrlData?.dispatchFeeDetails?.member_is_active ?? false,
            st_member_job_id: customUrlData?.jobType.id ?? "",
            st_member_job_name: customUrlData?.jobType.name ?? "",
            st_non_member_job_id: customUrlData?.jobType.id ?? "",
            st_non_member_job_name: customUrlData?.jobType.name ?? "",
          },
          additionalQuestions: JSON.stringify(state?.problem?.questions),
          summary: state?.additionalInfo?.additionalInfo,
          priority: "Normal",
          arrivalWindowStart: state?.appointment?.arrivalWindowStart,
          arrivalWindowEnd: state?.appointment?.arrivalWindowEnd,
          date: state?.appointment?.date,
          windowStart: state?.appointment?.displayStartTime,
          windowEnd: state?.appointment?.displayEndTime,
          notes: formattedNotes,
          customUrlData: {
            tagIds: customUrlData?.tagIds,
            campaignData: customUrlData?.campaignData,
          },
          followupleadid: state?.clientInfo?.followupleadid,
          autobotTrade: state?.problem?.trade?.trade?.name,
          autobotService: state?.problem?.servicetype,
          autobotJobName:
            state?.additionalInfo?.jobItemToBook?.jobItem?.jobItem,
        };
        if (
          state?.clientInfo?.isExistingCustomer === false &&
          followupflags?.value
        ) {
          const res = await handleNewCustomerCall(
            state.miscellaneous.newCustomerData
          );
          customUrlDataPayload = {
            ...customUrlDataPayload,
            customer: {
              ...state.clientInfo,
              address: {
                location_id: res?.userData?.locations[0]?.id,
                address_street: res?.userData?.address?.street,
                address_state: res?.userData?.address?.state,
                address_city: res?.userData?.address?.city,
                address_country: res?.userData?.address?.country,
                address_zip: res?.userData?.address?.zip,
              },
              customer_id: res?.userData?.id,
              customer_name: res?.userData?.name,
              email: state.miscellaneous.newCustomerData?.email,
            },
          };
        }
        await createBooking(customUrlDataPayload);
      } else {
        let bookingPayload = {
          organizationId: state.organizationDetails?.id,
          cookie: state.cookie,
          eventId: state.event_id,
          customer: {
            ...state?.clientInfo,
            is_member: isMember,
          },
          businessUnit: {
            id: jobItem?.st_business_unit_id?.length
              ? jobItem?.st_business_unit_id
              : "",
            name: jobItem?.st_business_unit_name?.length
              ? jobItem?.st_business_unit_name
              : "",
          },
          jobType: {
            is_member: jobItem?.is_member ?? false,
            st_member_job_id: jobItem?.st_member_job_id ?? "",
            st_member_job_name: jobItem?.st_member_job_name ?? "",
            st_non_member_job_id: jobItem?.st_non_member_job_id ?? "",
            st_non_member_job_name: jobItem?.st_non_member_job_name ?? "",
          },
          additionalQuestions: JSON.stringify(state?.problem?.questions),
          summary: state?.additionalInfo?.additionalInfo,
          priority: "Normal",
          arrivalWindowStart: state?.appointment?.arrivalWindowStart,
          arrivalWindowEnd: state?.appointment?.arrivalWindowEnd,
          date: state?.appointment?.date,
          windowStart: state?.appointment?.displayStartTime,
          windowEnd: state?.appointment?.displayEndTime,
          notes: formattedNotes,
          followupleadid: state?.clientInfo?.followupleadid,
          autobotTrade: state?.problem?.trade?.trade?.name,
          autobotService: state?.problem?.servicetype,
          autobotJobName:
            state?.additionalInfo?.jobItemToBook?.jobItem?.jobItem,
        };
        if (
          state?.clientInfo?.isExistingCustomer === false &&
          followupflags?.value
        ) {
          const res = await handleNewCustomerCall(
            state.miscellaneous.newCustomerData
          );
          bookingPayload = {
            ...bookingPayload,
            customer: {
              ...state.clientInfo,
              address: {
                location_id: res?.userData?.locations[0]?.id,
                address_street: res?.userData?.address?.street,
                address_state: res?.userData?.address?.state,
                address_city: res?.userData?.address?.city,
                address_country: res?.userData?.address?.country,
                address_zip: res?.userData?.address?.zip,
              },
              customer_id: res?.userData?.id,
              customer_name: res?.userData?.name,
              email: state.miscellaneous.newCustomerData?.email,
            },
          };
        }
        const res = await createBooking(bookingPayload);
        let exclusiveBooking = ['65b39d2755dc3a592bdadfbe','65b39d2755dc3a592bdadfbf']
        if (res?.response?.data?.message === "Job not booked" && exclusiveBooking?.includes(state?.organizationDetails?.id)) {
          updateState({
            ...state,
            miscellaneous: {
              ...state.miscellaneous,
              error: `Something went wrong. Please try again.`,
              loading: false,
            },
          });
          setError(true);
        } else {
          updateState({
            ...state,
            miscellaneous: {
              ...state.miscellaneous,
              error: null,
              loading: false,
            },
          });
          setError(false);
        }
      }
      setCurrentMainStep(currentMainStep + 1);
      setCurrentSubStep(currentSubStep + 1);
    } catch (err) {
      console.log(err);
      setError(true);
      updateState({
        ...state,
        miscellaneous: {
          ...state.miscellaneous,
          loading: false,
        },
      });
    } finally {
      setloading(false);
    }
  };
  return {
    handleNewCustomerCall,
    handleConfirmBooking,
    handleNewCustomerWhatconverts,
    loading,
    setloading,
    hasError,
  };
};
