import React from "react";
import { Input } from "antd";
import { InputProps } from "antd/lib/input";

import "./Input.css";

export const PrimaryInput = ({
	labelText,
	value,
	onChange,
	setInputErrors,
	onBlur,
	error,
	...rest
}: {
	labelText: string;
	value: string;
	onChange?: (value: string) => void;
	setInputErrors?: (value: string) => void;
	error?: string;
} & InputProps) => {
	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		onChange && onChange(e?.target?.value);
	};

	const handleBlurChange = (e) => {
		onBlur && onBlur(e?.target?.value);
	};

	return (
		<div className="input_otr">
			<label className={`label_text ${error ? "error" : ""}`}>
				{labelText}
			</label>
			<Input
				required
				value={value}
				onChange={handleInputChange}
				onBlur={handleBlurChange}
				{...rest}
			/>
			{error && <p className="error_message">{error}</p>}
		</div>
	);
};
