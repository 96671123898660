import React from "react";
import { Button } from "antd";
import { ButtonProps } from "antd/lib/button";

export const PrimaryButton = ({ children, ...rest }: ButtonProps) => {
	return <Button {...rest}>{children}</Button>;
};

export const SecondaryButton = ({ children, ...rest }: ButtonProps) => {
	return <Button {...rest}>{children}</Button>;
};

export const TileButton = ({ children, ...rest }: ButtonProps) => {
	return <Button {...rest}>{children}</Button>;
};
