import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import './fonts/fonts.css'
import { AppProvider } from "./Context/AppContext";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
		<AppProvider>
			<App />
		</AppProvider>
);

// root.render(
// 	<React.StrictMode>
// 		<AppProvider>
// 			<App />
// 		</AppProvider>
// 	</React.StrictMode>
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
