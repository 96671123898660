import React from "react";
import { Radio } from "antd";

const RadioButtonGroup = ({ options, onChange, value }) => {
	return (
		<Radio.Group
			className="radio_check_group"
			onChange={onChange}
			value={value}
		>
			{options.map((option, index) => (
				<Radio key={option.key || index} value={option.value}>
					{option.label}
				</Radio>
			))}
		</Radio.Group>
	);
};

export default RadioButtonGroup;
