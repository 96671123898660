import { Button } from "antd";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../Context/AppContext";
import { LoadingSpinner } from "../Spinner/index.tsx";
import { useFlag } from "@openfeature/react-sdk";
import { useBookings } from "../../../hooks/bookings/index.jsx";

import "../Stepper/Stepper.css";

const Navigation = ({
  currentMainStep,
  currentSubStep,
  setCurrentMainStep,
  totalSteps,
  mainStepComponents,
  setCurrentSubStep,
}) => {
  const { state, updateState, resetAdditionalInfo } = useContext(AppContext);
  const {handleNewCustomerCall,handleConfirmBooking,handleNewCustomerWhatconverts,loading,setloading} = useBookings({state,updateState,currentMainStep,currentSubStep, setCurrentMainStep,setCurrentSubStep})
  const followupflags=useFlag('new-existing-customer',false)

  const clearStates = () => {
    if (currentMainStep === 2 && currentSubStep === 1) {
      resetAdditionalInfo();
    }
  };

  const goToPreviousMainStep = () => {
    const mainStepCount = mainStepComponents[currentMainStep - 1];
    const steps = totalSteps[mainStepCount?.name]?.totalSteps;

    setCurrentSubStep(steps);
    setCurrentMainStep(currentMainStep - 1);
  };

  const handleBackButton = () => {
    clearStates();
    updateState({
      ...state,
      next:false,
      back:true
    })

    if (
      currentMainStep === 1 &&
      currentSubStep === 1 &&
      state.problem.isSingleTrade
    ) {
      goToPreviousMainStep();
    } else if (currentSubStep > 0) {
      setCurrentSubStep(currentSubStep - 1);
    } else if (currentSubStep === 0 && currentMainStep === 0) {
      setCurrentSubStep(null);
    } else if (currentSubStep === 0 && currentMainStep > 0) {
      goToPreviousMainStep();
    }
  };

  const handleNextbutton = async () => {
    updateState({
      ...state,
      next:true,
      back:false
    })
    
    if(state?.organizationDetails?.preventNoOwnership && !state?.miscellaneous?.ownResidenceFlag && !state.clientInfo.isExistingCustomer &&  !state?.miscellaneous?.newCustomerData?.ownResidence && currentMainStep===0 && currentSubStep===1){
      updateState({
        ...state,
        isSubStepValid: false,
        miscellaneous: {
          ...state.miscellaneous,
          ownResidenceFlag: true,
        },
      })
      return
    }
    else{
      updateState({
        ...state,
        miscellaneous: {
          ...state.miscellaneous,
          ownResidenceFlag: false,
        },
      })
    }
    if(state.miscellaneous.newCustomerPagewhatconverts){
      try {
        await handleNewCustomerWhatconverts(state.miscellaneous.newCustomerData)
      } catch (err) {
        updateState({
          ...state,
          isSubStepValid: true,
          miscellaneous: {
            ...state.miscellaneous,
            loading: false,
            error: err?.response
              ? err?.response?.data?.message
              : "Could not add customer to whatconverts, please try again later.",
          },
        });
        setloading(false)
        return;
      }
    }
    if (state.miscellaneous.newCustomerPage && followupflags?.value===false) {
      try {
        await handleNewCustomerCall(
          state.miscellaneous.newCustomerData
        );
      } catch (err) {
        updateState({
          ...state,
          isSubStepValid: true,
          miscellaneous: {
            ...state.miscellaneous,
            loading: false,
            error: err?.response
              ? err?.response?.data?.message
              : "Could not add customer, please try again later.",
          },
        });
        setloading(false)
        return;
      }
    }
    const mainStepCount = mainStepComponents[currentMainStep];
    const totalComponentSteps = totalSteps[mainStepCount?.name]?.totalSteps;
    if ((state?.customUrlData?currentMainStep < 3 :currentMainStep < 4)) {
      if (currentSubStep === totalComponentSteps) {
        setCurrentMainStep(currentMainStep + 1);
        setCurrentSubStep(0);
      } else {
        setCurrentSubStep(currentSubStep + 1);
      }
    }
  };
  
  useEffect(() => {
    setloading(false)
  }, [state?.clientInfo]);


  const isAppointmentTimeNotSelected=(state?.customUrlData && currentMainStep===2 && !state?.appointment?.arrivalWindowEnd) || (state?.customUrlData===null && currentMainStep===3 && !state?.appointment?.arrivalWindowEnd)
  return (currentMainStep === 0 && currentSubStep !== null) ||
    (currentMainStep !== 0 && currentSubStep !== null) ? (
    <div className="steps_btn">
      <Button
        // className="steps_button back_button"
        className={`steps_button back_button ${
          !state.miscellaneous.loading ? "" : "clicked"
        }`}
        onClick={handleBackButton}
        disabled={state?.miscellaneous?.loading}
      >
        <svg
          className="button_icon"
          aria-hidden="true"
          focusable="false"
          data-prefix="fas"
          data-icon="arrow-left-long"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
        >
          <path
            fill="currentColor"
            d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 288 480 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-370.7 0 73.4-73.4c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-128 128z"
          ></path>
        </svg>
        <span className="button_text">Back</span>
      </Button>
      {(state?.customUrlData?currentMainStep === 3:currentMainStep === 4) ? (
        <Button
          className={`steps_button next_button ${
            state.isSubStepValid ? "" : "clicked"
          }`}
          onClick={handleConfirmBooking}
          disabled={state?.isSubStepValid ? false : true}
        >
          <svg
            className="button_icon"
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="check"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path
              fill="currentColor"
              d="M442.7 124.7L206.3 361.1 69.7 224.5c-9.4-9.4-24.6-9.4-34 0s-9.4 24.6 0 34l150.4 150.4c9.4 9.4 24.6 9.4 34 0L443 158.7c9.4-9.4 9.4-24.6 0-34z"
            ></path>
          </svg>

          <span className="button_text">Confirm Appointment</span>
        </Button>
      ) : null}
      {(state?.customUrlData?(currentMainStep === 3):(currentMainStep===1 || currentMainStep === 4)) ? null : (
       loading?<LoadingSpinner/>:  <Button
        className={`steps_button next_button ${
          (state.isSubStepValid ? "" : "clicked") || isAppointmentTimeNotSelected ? "clicked" : ""
        }`}
        onClick={handleNextbutton}
        disabled={loading || (state?.isSubStepValid ? false : true) || isAppointmentTimeNotSelected}
      >
        <svg
          className="button_icon"
          aria-hidden="true"
          focusable="false"
          data-prefix="fas"
          data-icon="arrow-right-long"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
        >
          <path
            fill="currentColor"
            d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z"
          ></path>
        </svg>
        <span className="button_text">Next</span>
      </Button>
      )}
    </div>
  ) : null;
};

export default Navigation;