import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../Context/AppContext";
import "./DiagnosticFee.css";
import { Checkbox } from "antd";

const DiagnosticFee = () => {
	const { state, updateState } = useContext(AppContext);
	const [agreeFee, setAgreeFee] = useState(false);

	useEffect(() => {
		updateState({
			...state,
			isSubStepValid: false,
		});

		if(state?.customUrlData?.dispatchFee){
			updateState({
				...state,
				clientInfo:{
					...state.clientInfo,
					is_member:  state?.customUrlData?.dispatchFeeDetails?.member_is_active.toString()
				}
			});
		}

		if (state.miscellaneous.agreementCheckbox) {
			setAgreeFee(true);
			updateState({
				...state,
				isSubStepValid: true,
			});
		}
	}, []);

	useEffect(() => {
		if (agreeFee) {
			updateState({
				...state,
				isSubStepValid: true,
				miscellaneous: {
					...state.miscellaneous,
					agreementCheckbox: true,
				},
				additionalInfo: {
					...state.additionalInfo,
					fee: state?.problem?.jobItem?.dispatch_fee,
				},
			});
		} 
		else{
			updateState({
				...state,
				isSubStepValid: false,
				miscellaneous: {
					...state.miscellaneous,
					agreementCheckbox: false,
				},
				additionalInfo: {
					...state.additionalInfo,
					fee: 0,
				},
			});
		}
	}, [agreeFee]);
	
	const dispatch_fee = state?.clientInfo?.is_member?state?.additionalInfo?.jobItemToBook?.member_dispatch_fee:state?.additionalInfo?.jobItemToBook?.dispatch_fee
	const dispatch_fee_key = "{{dispatch_fee}}";
	const member_dispatch_fee_key = "{{member_dispatch_fee}}"

	const isDefault = state?.additionalInfo?.jobItemToBook?.use_default_dispatch;
	const dispatch_header = isDefault
		? state?.organizationDetails?.default_dispatch_header
		: state?.additionalInfo?.jobItemToBook?.dispatch_header;
	const dispatch_body = isDefault
		? state?.organizationDetails?.default_dispatch_body
		: state?.additionalInfo?.jobItemToBook?.dispatch_body;
	const dispatch_checkbox = isDefault
		? state?.organizationDetails?.default_dispatch_text
		: state?.additionalInfo?.jobItemToBook?.dispatch_text;

	return (
		<div className="QuestionsList_otr">
			<div className="list_header_otr">
				<div className="bread_crumbs">
					{
						 !state?.customUrlData && <p className="Service_text">
						 {state?.problem?.trade?.trade?.name} - {state?.problem?.servicetype}{" "}
						 - {state?.problem?.jobItem?.category_name}
					 </p>
					}
				</div>
				<div className="heading_icon_otr">
					<h6 className="header_heading">
						{/* Check if the fee applies */}
						{/* This visit requires an additional fee of $
						 */}
						{dispatch_header &&
							dispatch_header.replace(
								dispatch_fee_key,
								`$${dispatch_fee}`
							).replace(member_dispatch_fee_key, `$${dispatch_fee}`)}
						<hr />
					</h6>
				</div>
			</div>

			<div className="question_list_inr">
				<span className="confirm_span">
					{dispatch_body &&
						dispatch_body
							.replace(
								dispatch_fee_key,
								`$${dispatch_fee}`
							)
							.replace(
								member_dispatch_fee_key,
								`$${state?.additionalInfo?.jobItemToBook?.member_dispatch_fee ?? 0}`
							)}
				</span>
			</div>
			<div className="confirm_checkbox">
				<Checkbox
					checked={agreeFee}
					onChange={(e) => setAgreeFee(e.target.checked)}
					className="theme_checkbox"
					style={{ display: "flex", alignItems: "center" }}
				>
					<span className="agree_span">
						{dispatch_checkbox &&
							dispatch_checkbox.replace(
								dispatch_fee_key,
								`$${dispatch_fee} `
							)}
					</span>
				</Checkbox>
			</div>
		</div>
	);
};

export default DiagnosticFee;
