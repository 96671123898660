// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-error-alert {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    max-width: 400px;
    background-color: #fff5f5;
    border: 1px solid #ffcccc;
    border-radius: 8px;
    text-align: center;
    margin: 0 auto;
  }
  
  .custom-error-icon {
    font-size: 36px;
    color: #d32f2f; /* Dark red color for the icon */
    margin-bottom: 10px;
  }
  
  .custom-error-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .custom-error-title {
    font-size: 18px;
    font-weight: bold;
    color: #d32f2f;
    margin-bottom: 10px;
  }
  
  .custom-error-description {
    font-size: 14px;
    color: #333;
    margin-bottom: 15px;
  }
  
  .custom-error-action {
    font-size: 14px;
    color: #333;
  }
  
  .custom-error-action strong {
    color: #d32f2f;
  }
  `, "",{"version":3,"sources":["webpack://./src/Components/AppointmentReview/ErrorAlert.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,aAAa;IACb,gBAAgB;IAChB,yBAAyB;IACzB,yBAAyB;IACzB,kBAAkB;IAClB,kBAAkB;IAClB,cAAc;EAChB;;EAEA;IACE,eAAe;IACf,cAAc,EAAE,gCAAgC;IAChD,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,mBAAmB;EACrB;;EAEA;IACE,eAAe;IACf,iBAAiB;IACjB,cAAc;IACd,mBAAmB;EACrB;;EAEA;IACE,eAAe;IACf,WAAW;IACX,mBAAmB;EACrB;;EAEA;IACE,eAAe;IACf,WAAW;EACb;;EAEA;IACE,cAAc;EAChB","sourcesContent":[".custom-error-alert {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    padding: 20px;\n    max-width: 400px;\n    background-color: #fff5f5;\n    border: 1px solid #ffcccc;\n    border-radius: 8px;\n    text-align: center;\n    margin: 0 auto;\n  }\n  \n  .custom-error-icon {\n    font-size: 36px;\n    color: #d32f2f; /* Dark red color for the icon */\n    margin-bottom: 10px;\n  }\n  \n  .custom-error-content {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n  }\n  \n  .custom-error-title {\n    font-size: 18px;\n    font-weight: bold;\n    color: #d32f2f;\n    margin-bottom: 10px;\n  }\n  \n  .custom-error-description {\n    font-size: 14px;\n    color: #333;\n    margin-bottom: 15px;\n  }\n  \n  .custom-error-action {\n    font-size: 14px;\n    color: #333;\n  }\n  \n  .custom-error-action strong {\n    color: #d32f2f;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
