export const ValidCustomer = (
	<svg
		width="18"
		height="15"
		viewBox="0 0 18 15"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M15.9922 0.78125L5.71875 11.0547L1.96875 7.26562C1.77344 7.10938 1.46094 7.10938 1.30469 7.26562L0.171875 8.39844C0.015625 8.55469 0.015625 8.86719 0.171875 9.0625L5.40625 14.2578C5.60156 14.4531 5.875 14.4531 6.07031 14.2578L17.7891 2.53906C17.9453 2.38281 17.9453 2.07031 17.7891 1.875L16.6562 0.78125C16.5 0.585938 16.1875 0.585938 15.9922 0.78125Z"
			fill="#43B939"
		></path>
	</svg>
);

export const AirQuality = () => (
	<svg
		width="48"
		height="48"
		viewBox="0 0 48 48"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M22.6875 31.0312C22.6875 32.663 21.3357 33.985 19.6932 33.9362C18.1125 33.8892 16.875 32.5508 16.875 30.9694V25.4062C16.875 24.6296 16.2454 24 15.4688 24H4.21875C3.44212 24 2.8125 24.6296 2.8125 25.4062V30.8845C2.8125 40.213 10.4442 47.9954 19.7727 48C29.1332 48.0046 36.75 40.3907 36.75 31.0312V25.4062C36.75 24.6296 36.1204 24 35.3438 24H24.0938C23.3171 24 22.6875 24.6296 22.6875 25.4062V31.0312Z"
			fill="#45F6FF"
		></path>
		<path
			d="M35.3438 24H24.0938C23.3171 24 22.6875 24.6296 22.6875 25.4062V31.0312C22.6875 32.6336 21.3836 33.9364 19.7812 33.9366V47.9999C29.1379 47.9999 36.75 40.3879 36.75 31.0312V25.4062C36.75 24.6296 36.1204 24 35.3438 24Z"
			fill="#2EBEEF"
		></path>
		<path
			d="M2.8125 4.21875H16.875V25.4062H2.8125V4.21875Z"
			fill="#EBF5FF"
		></path>
		<path
			d="M43.7812 2.8125H37.2188C29.1934 2.8125 22.6875 9.31838 22.6875 17.3438V25.4062H36.75V17.3438C36.75 17.0849 36.9599 16.875 37.2188 16.875H43.7812V2.8125Z"
			fill="#BEE6FA"
		></path>
		<path
			d="M42.375 18.2812V1.40625C42.375 0.629625 43.0046 0 43.7812 0H46.5938C47.3704 0 48 0.629625 48 1.40625V18.2812C48 19.0579 47.3704 19.6875 46.5938 19.6875H43.7812C43.0046 19.6875 42.375 19.0579 42.375 18.2812Z"
			fill="#A0CBF0"
		></path>
		<path
			d="M18.2812 5.625H1.40625C0.629625 5.625 0 4.99538 0 4.21875V1.40625C0 0.629625 0.629625 0 1.40625 0H18.2812C19.0579 0 19.6875 0.629625 19.6875 1.40625V4.21875C19.6875 4.99538 19.0579 5.625 18.2812 5.625Z"
			fill="#A0CBF0"
		></path>
	</svg>
);

export const DrainsSewer = () => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clipPath="url(#clip0_1763_62)">
			<path d="M2.09082 0H7.75311V2.12336H2.09082V0Z" fill="#007BFF" />
			<path
				d="M16.2466 4.2467H21.9089V8.49342H16.2466V4.2467Z"
				fill="#007BFF"
			/>
			<path
				d="M0.675293 3.53894H9.16873V6.37009H0.675293V3.53894Z"
				fill="#007BFF"
			/>
			<path d="M14.8311 0H23.3245V2.83115H14.8311V0Z" fill="#007BFF" />
			<path d="M14.8311 9.909H23.3245V12.7401H14.8311V9.909Z" fill="#007BFF" />
			<path
				d="M11.9998 18.4024C9.65819 18.4024 7.75311 16.4974 7.75311 14.1557V7.78564H2.09082V14.1557C2.58896 27.2842 21.4133 27.2786 21.9088 14.1557H16.2465C16.2465 16.4974 14.3415 18.4024 11.9998 18.4024Z"
				fill="#007BFF"
			/>
		</g>
		<defs>
			<clipPath id="clip0_1763_62">
				<rect width="24" height="24" fill="white" />
			</clipPath>
		</defs>
	</svg>
);

export const ElectricalInstallation = () => (
	<svg
		width="48"
		height="48"
		viewBox="0 0 48 48"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clipPath="url(#clip0_99_1354)">
			<path
				d="M2.92634 26.4828C1.31017 26.4828 0 25.1726 0 23.5564C0.000103448 22.7803 0.308586 22.0361 0.857379 21.4874L21.4874 0.857379C22.0361 0.308586 22.7803 0.000103448 23.5564 0C25.1726 0 26.4828 1.31017 26.4828 2.92634C26.4827 3.70241 26.1742 4.44662 25.6254 4.99531L4.99531 25.6254C4.44662 26.1742 3.70241 26.4827 2.92634 26.4828Z"
				fill="#B3B3B3"
			></path>
			<path
				d="M5.79321 24.8277L24.8277 5.79321L36.0125 16.978C39.8909 20.8562 39.8911 27.1442 36.0129 31.0226C36.0129 31.0226 36.0127 31.0228 36.0125 31.023L31.023 36.0125C27.1448 39.8909 20.8568 39.8911 16.9785 36.0129C16.9785 36.0129 16.9782 36.0127 16.978 36.0125L5.79321 24.8277Z"
				fill="#E6E6E6"
			></path>
			<path
				d="M30.6206 36.4137L36.4137 30.6206L39.3814 33.5883C40.0276 34.2347 40.0276 35.2824 39.3814 35.9287L35.9287 39.3814C35.2824 40.0276 34.2347 40.0276 33.5883 39.3814L30.6206 36.4137Z"
				fill="#B3B3B3"
			></path>
			<path
				d="M36.4138 38.8966L38.8966 36.4138L48 45.5173V48H45.5173L36.4138 38.8966Z"
				fill="#808080"
			></path>
			<path
				d="M5.7931 16.5517L0.513931 11.2726C0.184759 10.9433 -0.000103405 10.4968 4.33936e-08 10.0312C4.33936e-08 9.06178 0.785897 8.27588 1.75531 8.27588C2.22093 8.27578 2.66741 8.46064 2.99669 8.78981L8.27586 14.069L5.7931 16.5517Z"
				fill="#E6E6E6"
			></path>
			<path
				d="M16.5517 5.7931L11.2726 0.513931C10.9433 0.184759 10.4968 -0.000103405 10.0312 4.33936e-08C9.06178 4.33936e-08 8.27588 0.785897 8.27588 1.75531C8.27578 2.22093 8.46064 2.66741 8.78981 2.99669L14.069 8.27586L16.5517 5.7931Z"
				fill="#E6E6E6"
			></path>
			<path
				d="M28.9656 29.7931L26.4828 16.5517L23.1725 19.8621L21.5173 15.7241L17.3794 19.8621L20.6897 28.1379L24.0001 24.8276L28.9656 29.7931Z"
				fill="#FDB62F"
			></path>
		</g>
		<defs>
			<clipPath id="clip0_99_1354">
				<rect width="48" height="48" fill="white"></rect>
			</clipPath>
		</defs>
	</svg>
);

export const ElectricalRepair = () => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clipPath="url(#clip0_1763_372)">
			<path
				d="M22.5669 15.6043C21.3323 14.3688 19.6193 13.8454 17.4193 14.435L9.57441 6.58213L9.6781 6.19007C10.1299 4.49479 9.64172 2.67252 8.40441 1.43521C7.1446 0.174411 5.3536 -0.288198 3.65977 0.177083C3.41822 0.243411 3.23021 0.433208 3.16622 0.675411C3.10224 0.917614 3.17241 1.17505 3.34946 1.35219L5.23838 3.24111C5.79061 3.79471 5.78522 4.6813 5.23974 5.22824C4.69857 5.77086 3.78891 5.77002 3.24989 5.2296L1.3606 3.34016C1.18346 3.16293 0.92527 3.09341 0.683067 3.15777C0.440817 3.22208 0.251723 3.41057 0.18563 3.65235C-0.272855 5.32757 0.178176 7.12968 1.44404 8.39554C2.67807 9.63046 4.38896 10.1557 6.59025 9.56488L14.4358 17.4178C13.8503 19.6171 14.3553 21.3135 15.6065 22.5647C16.8679 23.827 18.6597 24.2862 20.3498 23.8225C20.5914 23.7562 20.7795 23.5663 20.8435 23.3241C20.9076 23.0819 20.8374 22.8245 20.6603 22.6473L18.7718 20.7588C18.2196 20.2052 18.225 19.3185 18.7704 18.7716C19.3184 18.2265 20.226 18.2352 20.7603 18.7709L22.6497 20.6599C22.8269 20.837 23.085 20.9065 23.3272 20.8422C23.5694 20.7778 23.7585 20.5894 23.8246 20.3477C24.2831 18.6727 23.8321 16.8702 22.5669 15.6043Z"
				fill="#007BFF"
			/>
			<path
				d="M7.52593 12.4961L0.82318 19.1997C-0.273742 20.2966 -0.273742 22.0804 0.82318 23.1773C1.9201 24.2742 3.70388 24.2742 4.80076 23.1773L11.5034 16.4739L7.52593 12.4961ZM4.1402 20.9952C3.86551 21.2699 3.42066 21.2699 3.14598 20.9952C2.87134 20.7206 2.87134 20.2756 3.14598 20.001L7.41066 15.7355C7.6853 15.4609 8.13029 15.4609 8.40488 15.7355C8.67952 16.0102 8.67952 16.4551 8.40488 16.7297L4.1402 20.9952Z"
				fill="#007BFF"
			/>
			<path
				d="M23.7938 1.41812L22.5816 0.205976C22.3535 -0.0221649 21.9994 -0.0657586 21.7228 0.100132L17.994 2.33715C17.6017 2.57251 17.5351 3.11377 17.8585 3.43726L18.1553 3.73398L13.4219 8.44805L15.411 10.4372L20.1445 5.72312L20.5625 6.14129C20.886 6.46468 21.4273 6.39812 21.6626 6.00577L23.8997 2.27701C24.0655 2.0003 24.0219 1.64626 23.7938 1.41812Z"
				fill="#007BFF"
			/>
		</g>
		<defs>
			<clipPath id="clip0_1763_372">
				<rect width="24" height="24" fill="white" />
			</clipPath>
		</defs>
	</svg>
);

export const GasLine = () => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clipPath="url(#clip0_1763_148)">
			<path
				d="M22.7814 17.325C22.7814 16.1062 22.7861 14.9344 22.7814 13.7672C22.7767 13.0172 22.3127 12.5578 21.558 12.539C21.1924 12.5297 20.8221 12.5297 20.4564 12.539C19.8236 12.5578 19.3361 12.975 19.2658 13.5469C19.1955 14.1187 19.4392 14.5359 19.9642 14.7703C20.0721 14.8172 20.1892 14.8453 20.3111 14.8406H20.4705V17.3437H3.5158V14.8359H3.67518C3.82986 14.8406 3.98455 14.8031 4.12518 14.7328C4.54705 14.5078 4.76736 14.1047 4.72518 13.589C4.67361 13.0172 4.20486 12.5672 3.63299 12.539C3.26736 12.5156 2.89705 12.525 2.53143 12.5297C1.65018 12.539 1.21424 12.9797 1.20955 13.8609C1.20486 14.9953 1.20955 16.1297 1.20955 17.2875C0.0142377 17.6812 -0.0279497 17.925 0.0142378 19.4203C0.0236128 19.7719 0.0189253 20.1281 0.00955026 20.4797C-0.0279497 21.7312 0.3283 22.1344 1.52361 22.139H22.4767C22.5939 22.139 22.7111 22.1437 22.8283 22.139C23.5314 22.0969 23.9721 21.6562 23.9861 20.9484C24.0002 20.3297 23.958 19.7109 23.9955 19.0922C24.0471 18.1828 23.8596 17.4562 22.7814 17.325ZM7.1908 20.9672C6.45955 20.9719 5.94861 20.4844 5.93455 19.7765C5.92049 19.0453 6.4408 18.5297 7.1908 18.5344C7.91736 18.539 8.42361 19.0406 8.42361 19.7531C8.42361 20.4656 7.91736 20.9672 7.1908 20.9672ZM11.9486 20.9672C11.2314 20.9578 10.7299 20.4375 10.7439 19.7203C10.758 19.0031 11.2689 18.5297 12.0049 18.5344C12.7314 18.539 13.233 19.0406 13.233 19.7531C13.233 20.4844 12.7127 20.9812 11.9486 20.9672ZM16.8002 20.9672C16.0736 20.9672 15.5627 20.4703 15.558 19.7578C15.5533 19.0265 16.0736 18.525 16.833 18.5344C17.5549 18.5437 18.0564 19.0547 18.0471 19.7672C18.0424 20.4844 17.5314 20.9719 16.8002 20.9672Z"
				fill="#007BFF"
			/>
			<path
				d="M12.0198 16.2187C11.5025 16.2187 10.9853 16.1594 10.4788 16.0517C9.12643 15.7554 7.90336 15.0336 6.92814 13.9616C5.96908 12.9112 5.34408 11.5968 5.13395 10.1854C4.97231 9.12962 5.08546 8.05226 5.468 7.05031C6.31391 4.8471 7.72556 2.85936 10.0424 0.602285L10.468 0.1875L13.2698 3.31724L14.8485 1.67426L15.2579 2.11059C15.3334 2.1914 15.4034 2.26681 15.4735 2.33684C15.6243 2.49306 15.7644 2.64389 15.9045 2.80011C16.9983 4.01214 17.8442 5.40194 18.4907 7.0557C18.9002 8.09535 19.0295 9.15656 18.8733 10.2016C18.7278 11.1551 18.3452 12.1247 17.731 13.0835C16.4918 15.0444 14.3366 16.2295 12.0198 16.2187ZM10.2902 6.59782C9.90769 7.11495 9.54131 7.61054 9.23958 8.12229C8.49604 9.39358 8.88936 10.9881 10.1501 11.8284C10.6836 12.1839 11.3571 12.3779 12.0467 12.3779C12.7364 12.3779 13.3991 12.1839 13.9163 11.8338C14.7676 11.2574 15.037 10.5033 15.1071 9.97535C15.1825 9.37203 15.0801 8.76332 14.8215 8.21386L13.2267 10.0131L10.4034 6.4416L10.2902 6.59782Z"
				fill="#007BFF"
			/>
		</g>
		<defs>
			<clipPath id="clip0_1763_148">
				<rect width="24" height="24" fill="white" />
			</clipPath>
		</defs>
	</svg>
);

export const Generator = () => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clipPath="url(#clip0_1763_363)">
			<path
				d="M22.2695 9.62347C22.0065 8.48236 21.5616 7.40771 20.9421 6.41821L21.8342 4.9303L19.0697 2.16577L17.5818 3.05786C16.5923 2.43842 15.5176 1.99347 14.3765 1.73053L13.9556 0H10.0444L9.62347 1.73053C8.48236 1.99347 7.40771 2.43842 6.41821 3.05786L4.9303 2.16577L2.16577 4.9303L3.05786 6.41821C2.43842 7.40771 1.99347 8.48236 1.73053 9.62347L0 10.0444V13.9556L1.73053 14.3765C1.99347 15.5176 2.43842 16.5923 3.05786 17.5818L2.16577 19.0697L4.9303 21.8342L6.41821 20.9421C7.40771 21.5616 8.48236 22.0065 9.62347 22.2695L10.0444 24H13.9556L14.3765 22.2695C15.5176 22.0065 16.5923 21.5616 17.5818 20.9421L19.0697 21.8342L21.8342 19.0697L20.9421 17.5818C21.5616 16.5923 22.0065 15.5176 22.2695 14.3765L24 13.9556V10.0444L22.2695 9.62347ZM12 19.7344C7.73529 19.7344 4.26562 16.2647 4.26562 12C4.26562 7.73529 7.73529 4.26562 12 4.26562C16.2647 4.26562 19.7344 7.73529 19.7344 12C19.7344 16.2647 16.2647 19.7344 12 19.7344Z"
				fill="#007BFF"
			/>
			<path
				d="M12.8314 4.5L6.2998 14.5172H11.1682V19.5L17.6998 9.48281H12.8314V4.5Z"
				fill="#007BFF"
			/>
		</g>
		<defs>
			<clipPath id="clip0_1763_363">
				<rect width="24" height="24" fill="white" />
			</clipPath>
		</defs>
	</svg>
);

export const HvacInstallation = () => (
	<svg
		width="48"
		height="48"
		viewBox="0 0 48 48"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clipPath="url(#clip0_99_1352)">
			<path
				d="M13.8939 5.65918C12.7707 5.65918 11.681 6.0593 10.8259 6.78577L10.8276 6.78783C10.3221 7.21411 10 7.8593 10 8.58136C10 9.86498 11.018 10.9055 12.2738 10.9055C13.5274 10.9055 14.5439 9.86864 14.5475 8.58821C15.2896 8.8568 15.8222 9.56648 15.8222 10.4C15.8222 11.4632 14.9572 12.3283 13.894 12.3283H0V15.1408H13.8939C16.5079 15.1408 18.6347 13.014 18.6347 10.4C18.6347 7.78599 16.5079 5.65918 13.8939 5.65918Z"
				fill="#B7E4F8"
			></path>
			<path
				d="M37.5411 39.3755L36.7677 40.1489L35.9942 39.3755L34.0056 41.3642L34.7791 42.1376L34.0056 42.911L35.9942 44.8997L36.7677 44.1264L37.5411 44.8997L39.5298 42.911L38.7564 42.1376L39.5298 41.3642L37.5411 39.3755Z"
				fill="#93D6F4"
			></path>
			<path
				d="M47.9942 30.8998L46.0056 28.9111L45.2321 29.6846L44.4587 28.9111L42.47 30.8998L43.2434 31.6733L42.47 32.4467L44.4587 34.4353L45.2321 33.662L46.0056 34.4353L47.9942 32.4467L47.2208 31.6733L47.9942 30.8998Z"
				fill="#93D6F4"
			></path>
			<path
				d="M9.54111 33.9111L8.76777 34.6846L7.99433 33.9111L6.00562 35.8998L6.77905 36.6733L6.00562 37.4467L7.99433 39.4354L8.76777 38.662L9.54111 39.4354L11.5298 37.4467L10.7564 36.6733L11.5298 35.8998L9.54111 33.9111Z"
				fill="#B7E4F8"
			></path>
			<path
				d="M24.5298 2.36445L22.5411 0.375732L21.7677 1.14917L20.9942 0.375732L19.0056 2.36445L19.7791 3.13788L19.0056 3.91123L20.9942 5.89994L21.7677 5.1266L22.5411 5.89994L24.5298 3.91123L23.7564 3.13788L24.5298 2.36445Z"
				fill="#B7E4F8"
			></path>
			<path
				d="M45.1874 15.2441C45.1874 9.36086 40.401 4.57446 34.5177 4.57446C30.018 4.57446 26.3573 8.23521 26.3573 12.7349C26.3573 16.1385 29.1263 18.9076 32.53 18.9076C35.0652 18.9076 37.1278 16.845 37.1278 14.3097C37.1278 13.5438 36.8689 12.8375 36.4347 12.2726C35.9748 13.2772 34.9615 13.9755 33.7843 13.9755C32.1749 13.9755 30.8703 12.6708 30.8703 11.0615C30.8703 9.45442 32.1712 8.1513 33.7774 8.14755V8.14689C37.1757 8.14689 39.9402 10.9116 39.9402 14.3096C39.9402 18.3957 36.616 21.72 32.5299 21.72C29.6844 21.72 27.144 20.3901 25.4966 18.3197H0V19.7259V27.7491V29.0809H31.3504C38.9802 29.081 45.1874 22.8738 45.1874 15.2441Z"
				fill="#DBF1FB"
			></path>
			<path
				d="M25.3357 30.4603V29.0811H0V29.1391V31.8935V31.9516H20.3248C24.3801 31.9516 27.6794 35.2437 27.6794 39.2901C27.6794 42.3221 25.2172 44.7902 22.1832 44.8108C20.2026 44.7902 18.5974 43.1714 18.5974 41.1834C18.5974 39.7595 19.7541 38.6011 21.1758 38.6011C21.426 38.6011 21.6635 38.6548 21.879 38.7499C21.4638 39.171 21.2067 39.7545 21.2067 40.3995C21.2067 41.683 22.2247 42.7236 23.4805 42.7236C24.7363 42.7236 25.7543 41.6831 25.7543 40.3995C25.7543 40.2756 25.7446 40.154 25.7264 40.0355C25.5619 37.6663 23.5845 35.7886 21.1757 35.7886C18.2031 35.7886 15.7848 38.2086 15.7848 41.1834C15.7848 44.7094 18.6312 47.5814 22.1451 47.6224V47.6244C22.162 47.6244 22.1788 47.6238 22.1956 47.6237C22.2041 47.6237 22.2124 47.6244 22.221 47.6244V47.6234C26.7885 47.5825 30.4918 43.8603 30.4918 39.2902C30.4918 35.5107 28.4122 32.2075 25.3357 30.4603Z"
				fill="#B7E4F8"
			></path>
			<path
				d="M34.5178 4.57446C30.0181 4.57446 26.3573 8.23521 26.3573 12.7349C26.3573 16.1385 29.1263 18.9076 32.53 18.9076C35.0653 18.9076 37.1279 16.845 37.1279 14.3097C37.1279 13.5438 36.8689 12.8375 36.4347 12.2726C35.9748 13.2772 34.9615 13.9755 33.7844 13.9755C32.175 13.9755 30.8703 12.6708 30.8703 11.0615C30.8703 9.45442 32.1712 8.1513 33.7774 8.14755V8.14689C37.1757 8.14689 39.9403 10.9116 39.9403 14.3096C39.9403 18.3957 36.616 21.72 32.5299 21.72C29.6844 21.72 27.1441 20.3901 25.4966 18.3197H23.9607V29.0809H31.3503C38.9802 29.0809 45.1873 22.8738 45.1873 15.244C45.1874 9.36086 40.401 4.57446 34.5178 4.57446Z"
				fill="#B7E4F8"
			></path>
			<path
				d="M25.7544 40.3994C25.7544 40.2756 25.7448 40.154 25.7265 40.0355C25.6336 38.6969 24.9618 37.5156 23.9607 36.7407V42.6711C24.9859 42.4459 25.7544 41.5146 25.7544 40.3994Z"
				fill="#B7E4F8"
			></path>
			<path
				d="M34.5179 1.76196C28.4673 1.76196 23.5449 6.68439 23.5449 12.7349C23.5449 14.8439 24.2756 16.7852 25.4967 18.3198C27.1442 20.3901 29.6846 21.7201 32.53 21.7201C36.6161 21.7201 39.9404 18.3958 39.9404 14.3097C39.9404 10.9116 37.1758 8.14698 33.7775 8.14698V8.14754C32.1714 8.15129 30.8704 9.45442 30.8704 11.0615C30.8704 12.6709 32.1752 13.9755 33.7845 13.9755C34.9616 13.9755 35.9748 13.2773 36.4348 12.2726C36.8689 12.8376 37.128 13.5438 37.128 14.3097C37.128 16.845 35.0654 18.9076 32.5301 18.9076C29.1264 18.9076 26.3574 16.1385 26.3574 12.7349C26.3574 8.2352 30.0182 4.57446 34.5179 4.57446C40.4011 4.57446 45.1875 9.36085 45.1875 15.2441C45.1875 22.8739 38.9804 29.081 31.3505 29.081H23.9805H23.9609V32.915C26.1797 34.18 27.6796 36.5628 27.6796 39.29C27.6796 41.7001 26.123 43.7526 23.9609 44.504V47.4243C27.6931 46.5945 30.4921 43.263 30.4921 39.29C30.4921 36.3763 29.2551 33.7463 27.2791 31.8934H31.3506C40.5313 31.8934 48.0001 24.4246 48.0001 15.244C48 7.81004 41.9519 1.76196 34.5179 1.76196Z"
				fill="#93D6F4"
			></path>
			<path d="M19.9996 22.499H0V25.3115H19.9996V22.499Z" fill="#B7E4F8"></path>
		</g>
		<defs>
			<clipPath id="clip0_99_1352">
				<rect width="48" height="48" fill="white"></rect>
			</clipPath>
		</defs>
	</svg>
);

export const HvacRepair = () => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clipPath="url(#clip0_1763_342)">
			<path
				d="M21.3816 12.3273C21.3816 12.5091 21.2362 12.6545 21.0907 12.6909L19.2725 13.0182C19.1635 13.3818 19.0544 13.7091 18.9089 14.0727C18.8725 14.1818 18.7635 14.2545 18.6544 14.2909C18.5453 14.3273 18.3998 14.2909 18.3271 14.1818L16.6907 12.5454C16.5816 12.4727 16.5453 12.3273 16.5816 12.2182C17.5271 8.39999 14.618 4.76362 10.7635 4.76362C5.49073 4.76362 2.76346 11.2 6.54528 14.9818C8.03619 16.4727 10.1816 17.0545 12.218 16.5454C12.3271 16.5091 12.4725 16.5454 12.5816 16.6545L14.218 18.2909C14.2907 18.3636 14.3271 18.5091 14.3271 18.6182C14.2907 18.7273 14.218 18.8364 14.1089 18.8727C13.7816 19.0182 13.4544 19.1273 13.1271 19.2L12.7998 21.0182C12.7635 21.2 12.618 21.3091 12.4362 21.3091H9.23619C9.05437 21.3091 8.90891 21.2 8.87255 21.0182L8.54528 19.2C7.78164 19.0182 7.09073 18.7273 6.43619 18.3273L4.90891 19.3818C4.76346 19.4909 4.58164 19.4545 4.43619 19.3454L2.18164 17.1636C2.07255 17.0545 2.03619 16.8364 2.14528 16.6909L3.19982 15.1636C2.83619 14.5454 2.50891 13.8182 2.3271 13.0909L0.47255 12.7636C0.290732 12.7273 0.181641 12.5818 0.181641 12.4V9.19999C0.181641 9.01817 0.327095 8.87272 0.47255 8.83635L2.29073 8.50908C2.47255 7.78181 2.76346 7.05453 3.16346 6.39999L2.10891 4.87272C1.99982 4.72726 2.03619 4.54544 2.14528 4.39999L4.39982 2.18181C4.50891 2.07272 4.72709 2.03635 4.87255 2.14544L6.39982 3.19999C7.05437 2.83635 7.74528 2.50908 8.47255 2.32726L8.79982 0.509079C8.83619 0.327261 8.98164 0.21817 9.16346 0.21817H12.3635C12.5453 0.21817 12.6907 0.363625 12.7271 0.509079L13.0544 2.32726C13.7816 2.50908 14.5089 2.79999 15.1635 3.19999L16.6907 2.14544C16.8362 2.03635 17.018 2.07272 17.1635 2.18181L19.418 4.43635C19.5635 4.54544 19.5635 4.76362 19.4544 4.90908L18.3998 6.43635C18.7635 7.0909 19.0907 7.78181 19.2725 8.54544L21.0907 8.87272C21.2725 8.90908 21.3816 9.05453 21.3816 9.23635V12.3273Z"
				fill="#007BFF"
			/>
			<path
				d="M20.6542 17.6363C19.0906 17.8182 17.7815 19.0545 17.5997 20.6909L12.5815 15.6C12.1815 15.2 11.636 14.9818 11.1269 15.0182C8.10876 15.2727 5.74512 12.2909 6.79967 9.30907C6.90876 9.05452 7.19967 8.98179 7.38149 9.16361L9.59967 11.3818C9.78149 11.5636 10.036 11.5636 10.2179 11.3818L11.4542 10.1454C11.636 9.96361 11.636 9.70907 11.4542 9.52725L9.23603 7.30907C9.05421 7.12725 9.12694 6.79998 9.38149 6.72725C12.2179 5.70907 15.2724 7.99998 15.0178 11.0909C14.9815 11.6363 15.1997 12.1454 15.5997 12.5454L20.6542 17.6363Z"
				fill="#007BFF"
			/>
			<path
				d="M23.8178 21.0545C23.8178 19.5273 22.5814 18.3273 21.0542 18.2909C19.5269 18.2909 18.3269 19.5273 18.2905 21.0545C18.2905 22.5818 19.5269 23.8182 21.0542 23.8182C22.5814 23.8182 23.8178 22.5818 23.8178 21.0545ZM21.0905 22.0727C20.5451 22.0727 20.0723 21.6363 20.0723 21.0545C20.0723 20.5091 20.5087 20.0363 21.0905 20.0363C21.636 20.0363 22.0723 20.4727 22.1087 21.0545C22.0723 21.6363 21.636 22.0727 21.0905 22.0727Z"
				fill="#007BFF"
			/>
		</g>
		<defs>
			<clipPath id="clip0_1763_342">
				<rect width="24" height="24" fill="white" />
			</clipPath>
		</defs>
	</svg>
);

export const Maintenance = () => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clipPath="url(#clip0_1763_83)">
			<path
				d="M14.7516 12.3514L10.8 16.303L9.24842 14.7514C9.137 14.64 9.00473 14.5517 8.85916 14.4913C8.7136 14.4311 8.55758 14.4 8.40002 14.4C8.24246 14.4 8.08643 14.4311 7.94086 14.4913C7.7953 14.5517 7.66302 14.64 7.55162 14.7514C7.44021 14.8628 7.35183 14.9951 7.29153 15.1406C7.23123 15.2862 7.2002 15.4422 7.2002 15.5998C7.2002 15.7574 7.23123 15.9134 7.29153 16.059C7.35183 16.2046 7.44021 16.3368 7.55162 16.4483L9.95161 18.8483C10.0628 18.96 10.195 19.0488 10.3406 19.1093C10.4862 19.1699 10.6423 19.2011 10.8 19.2011C10.9577 19.2011 11.1138 19.1699 11.2594 19.1093C11.405 19.0488 11.5372 18.96 11.6484 18.8483L16.4485 14.0482C16.5598 13.9368 16.6482 13.8046 16.7085 13.659C16.7688 13.5134 16.7998 13.3574 16.7998 13.1998C16.7998 13.0422 16.7688 12.8862 16.7085 12.7406C16.6482 12.5951 16.5598 12.4628 16.4485 12.3514C16.337 12.24 16.2048 12.1517 16.0592 12.0913C15.9136 12.0311 15.7576 12 15.6 12C15.4424 12 15.2864 12.0311 15.1408 12.0913C14.9953 12.1517 14.863 12.24 14.7516 12.3514Z"
				fill="#007BFF"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M18.0002 2.4H21.0002C21.9602 2.4 22.8002 3.24 22.8002 4.2V22.2C22.8002 23.16 21.9602 24 21.0002 24H3.0002C2.0402 24 1.2002 23.16 1.2002 22.2V4.2C1.2002 3.24 2.0402 2.4 3.0002 2.4H4.8002V1.2C4.8002 0.537258 5.33746 0 6.0002 0C6.66293 0 7.20019 0.537258 7.20019 1.2V2.4H15.6002V1.2C15.6002 0.537258 16.1374 0 16.8002 0C17.463 0 18.0002 0.537258 18.0002 1.2V2.4ZM20.4002 21.6H3.6002V9.6H20.4002V21.6Z"
				fill="#007BFF"
			/>
		</g>
		<defs>
			<clipPath id="clip0_1763_83">
				<rect width="24" height="24" fill="white" />
			</clipPath>
		</defs>
	</svg>
);

export const PlumbingInstallation = () => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clipPath="url(#clip0_1763_2331)">
			<path
				d="M0 14.0652V19.6902C0 20.0786 0.314758 20.3934 0.703125 20.3934H5.29907V13.3621H0.703125C0.314758 13.3621 0 13.6769 0 14.0652Z"
				fill="#007BFF"
			/>
			<path
				d="M14.8126 12.6658V11.4402C14.8126 11.4029 14.8088 11.3666 14.8033 11.3309H9.19696C9.19128 11.3666 9.18762 11.4031 9.18762 11.4402V12.6667L8.1712 13.3621H6.70508V20.3934H8.26733C8.56012 20.3934 8.80072 20.441 9.07172 20.5529C9.24622 20.6248 9.43811 20.7048 9.63531 20.7872C10.2604 21.048 10.9068 21.3179 11.2867 21.451C11.5235 21.5339 11.7617 21.5753 12.0001 21.5753C12.2383 21.5753 12.4766 21.5339 12.7131 21.451C13.1989 21.2807 14.2192 20.8638 14.945 20.5585C15.2202 20.4428 15.4648 20.3934 15.7631 20.3934H17.2988V13.3621H15.829L14.8126 12.6658Z"
				fill="#007BFF"
			/>
			<path
				d="M23.2968 13.3621H18.7051V20.3934H23.2968C23.6852 20.3934 23.9999 20.0786 23.9999 19.6902V14.0652C23.9999 13.6769 23.6852 13.3621 23.2968 13.3621Z"
				fill="#007BFF"
			/>
			<path
				d="M17.1562 2.4375H6.84375C6.06702 2.4375 5.4375 3.0672 5.4375 3.84375C5.4375 4.6203 6.06702 5.25 6.84375 5.25H17.1562C17.933 5.25 18.5625 4.62048 18.5625 3.84375C18.5625 3.0672 17.933 2.4375 17.1562 2.4375Z"
				fill="#007BFF"
			/>
			<path
				d="M10.582 6.04688H13.3945V10.5469H10.582V6.04688Z"
				fill="#007BFF"
			/>
		</g>
		<defs>
			<clipPath id="clip0_1763_2331">
				<rect width="24" height="24" fill="white" />
			</clipPath>
		</defs>
	</svg>
);

export const PlumbingRepair = () => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clipPath="url(#clip0_1763_74)">
			<path
				d="M12.2662 18.7035C12.4278 18.8589 12.4307 19.1164 12.2722 19.2749L11.2039 20.3437C10.7622 20.7853 10.1151 20.8953 9.57176 20.6745C9.30793 20.5673 9.24142 20.2233 9.44274 20.022L11.2297 18.2346C11.3862 18.0782 11.6402 18.0786 11.7964 18.2354C12.0229 18.4628 12.1637 18.6049 12.2662 18.7035Z"
				fill="#007BFF"
			/>
			<path
				d="M16.7573 8.72576L16.3414 9.1419C16.1852 9.29822 15.9319 9.29822 15.7756 9.14195L12.4736 5.83999C12.2655 5.63187 12.0171 5.46295 11.7414 5.34618C11.4157 5.20817 11.4234 4.73667 11.7534 4.60905C12.7631 4.21851 13.8784 4.81513 14.1018 5.86949C14.1353 6.02851 14.2593 6.15302 14.4178 6.18653C15.0877 6.32739 15.6204 6.85365 15.764 7.53263C15.7975 7.69124 15.9198 7.81456 16.0784 7.84796C16.3094 7.89667 16.5232 7.99198 16.7055 8.11619C16.9103 8.25591 16.9326 8.55044 16.7573 8.72576Z"
				fill="#007BFF"
			/>
			<path
				d="M15.4397 16.986C15.6259 17.1319 15.6459 17.407 15.4818 17.5774C15.0098 18.0675 14.6488 18.5665 13.8833 18.5665C13.1336 18.5665 12.7755 18.0875 12.3612 17.6552C12.2109 17.4983 12.2141 17.2499 12.3677 17.0963L13.5546 15.9443C13.6983 15.8061 13.922 15.7949 14.0789 15.918L15.4397 16.986Z"
				fill="#007BFF"
			/>
			<path
				d="M23.2488 8.29779L19.3732 12.1739C19.217 12.3301 18.9637 12.3301 18.8074 12.1739L16.9069 10.2733C16.7507 10.1171 16.7507 9.86381 16.9068 9.7076L18.9185 7.69557C19.0747 7.53936 19.0747 7.28608 18.9184 7.12986L18.5312 6.74262C18.2912 6.50259 18.1277 6.20056 18.0577 5.86952C18.0241 5.71151 17.9006 5.58799 17.7421 5.55449C17.0634 5.41097 16.5439 4.88161 16.399 4.22284C16.362 4.05532 16.237 3.92581 16.0799 3.8923C15.7339 3.8193 15.4219 3.64478 15.1773 3.38775C14.5288 2.70717 14.5528 1.60205 15.2298 0.924477L15.5524 0.601941C16.3555 -0.200647 17.6616 -0.200647 18.4647 0.601941L23.2208 5.35807C24.0675 6.20466 24.0467 7.50095 23.2488 8.29779Z"
				fill="#007BFF"
			/>
			<path
				d="M19.9096 14.407L11.9082 6.40561C11.5902 6.08757 11.1512 5.92955 10.7031 5.97106C10.2206 6.01636 9.79965 6.29635 9.58698 6.64163L9.00542 7.61224C8.71438 8.09779 8.69688 8.71086 8.95941 9.21242L9.29145 9.84599C9.46197 10.172 9.40196 10.5651 9.14243 10.8251L1.01453 18.9525C0.455971 19.5111 0.148438 20.2541 0.148438 21.0437C0.148438 22.6926 1.48419 24 3.10626 24C3.86335 24 4.62093 23.712 5.1975 23.1355L13.1924 15.1401C13.4914 14.8415 13.967 14.813 14.2995 15.0736L17.2118 17.3598C17.8104 17.8299 18.668 17.7784 19.2065 17.2398L19.9096 16.5367C20.4961 15.9502 20.4979 14.9953 19.9096 14.407ZM5.79106 20.1142L4.44691 21.4583C3.95101 21.9542 3.14177 21.9559 2.64521 21.4583C2.14816 20.9618 2.14816 20.1537 2.64521 19.6566L3.98936 18.3125C4.48642 17.8154 5.29451 17.8154 5.79106 18.3125C6.28812 18.809 6.28812 19.6171 5.79106 20.1142Z"
				fill="#007BFF"
			/>
			<path
				d="M5.36378 19.213C5.36378 19.3345 5.31728 19.456 5.22527 19.548L3.88062 20.8927C3.6961 21.0777 3.39506 21.0777 3.21054 20.8927C3.02552 20.7082 3.02552 20.4071 3.21054 20.2226L4.55519 18.878C4.84647 18.5851 5.36378 18.7848 5.36378 19.213Z"
				fill="#007BFF"
			/>
		</g>
		<defs>
			<clipPath id="clip0_1763_74">
				<rect width="24" height="24" fill="white" />
			</clipPath>
		</defs>
	</svg>
);

export const Solar = () => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clipPath="url(#clip0_1763_447)">
			<path
				d="M22.2001 1.4573L22.2053 1.46372C22.2851 1.57186 22.309 1.71145 22.2696 1.83998L21.397 4.71435H17.0659L18.1052 1.28583H21.8573C21.9923 1.28518 22.1197 1.34888 22.2001 1.4573Z"
				fill="#007BFF"
			/>
			<path
				d="M7.16328 5.57153H11.196L10.0273 9.42858H5.99414L7.16328 5.57153Z"
				fill="#007BFF"
			/>
			<path
				d="M5.09794 9.42858H1.41016L2.57887 5.57153H6.26707L5.09794 9.42858Z"
				fill="#007BFF"
			/>
			<path
				d="M12.3511 4.71441L13.3903 1.28589H17.2093L16.17 4.71441H12.3511Z"
				fill="#007BFF"
			/>
			<path
				d="M15.9105 5.57153L14.7418 9.42858H10.9229L12.0915 5.57153H15.9105Z"
				fill="#007BFF"
			/>
			<path
				d="M11.4556 4.71441H7.42285L8.46168 1.28589H12.4949L11.4556 4.71441Z"
				fill="#007BFF"
			/>
			<path
				d="M9.76734 10.2857L8.46837 14.5714H4.43555L5.7341 10.2857H9.76734Z"
				fill="#007BFF"
			/>
			<path
				d="M10.6632 10.2857H14.4822L13.1832 14.5714H9.36426L10.6632 10.2857Z"
				fill="#007BFF"
			/>
			<path
				d="M15.3776 10.2857H19.7061L18.4997 14.2671C18.4449 14.4478 18.2784 14.5713 18.0895 14.5713H14.0786L15.3776 10.2857Z"
				fill="#007BFF"
			/>
			<path
				d="M19.9679 9.42858H15.6372L16.8059 5.57153H21.137L19.9679 9.42858Z"
				fill="#007BFF"
			/>
			<path
				d="M3.78601 1.58973C3.84174 1.40978 4.00777 1.28678 4.19616 1.28589H7.56594L6.52712 4.71441H2.83887L3.78601 1.58973Z"
				fill="#007BFF"
			/>
			<path
				d="M0.0192673 14.0185L1.15067 10.2857H4.83845L3.53994 14.5714H0.428995C0.293247 14.5715 0.165468 14.5073 0.0845162 14.3983C0.00356448 14.2893 -0.0209507 14.1484 0.0184236 14.0185H0.0192673Z"
				fill="#007BFF"
			/>
			<path
				d="M15.1829 20.4427L16.6233 21.0603C16.9387 21.1953 17.1432 21.5054 17.1431 21.8484V22.2856C17.1431 22.5222 16.9513 22.7141 16.7145 22.7141H7.7147C7.47804 22.7141 7.28613 22.5223 7.28613 22.2856V21.8484C7.2859 21.5048 7.49083 21.1943 7.80686 21.0594L9.24557 20.4427C9.87696 20.1729 10.2865 19.5523 10.2861 18.8656V17.5714H14.1432V18.8656C14.142 19.5523 14.5513 20.1731 15.1829 20.4427Z"
				fill="#007BFF"
			/>
			<path
				d="M23.9814 3.98155L20.2134 16.4142C20.1574 16.5924 19.9923 16.7138 19.8054 16.7142H2.143C2.01475 16.7158 1.89293 16.6581 1.81301 16.5577L0.90918 15.4285H18.0916C18.6588 15.4308 19.1597 15.0591 19.322 14.5157L22.9828 2.43573L23.905 3.58855C23.9939 3.6989 24.0224 3.84599 23.9814 3.98155Z"
				fill="#007BFF"
			/>
		</g>
		<defs>
			<clipPath id="clip0_1763_447">
				<rect width="24" height="24" fill="white" />
			</clipPath>
		</defs>
	</svg>
);

export const WaterLeakRepair = () => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clipPath="url(#clip0_1763_292)">
			<path
				d="M13.9084 0H12.6425V4.74012L9.41813 6.60211L6.19334 4.74012V0H4.92737L2.38281 5.2393L6.42398 10.5857V24H12.4118V10.5857L16.4535 5.2393L13.9084 0ZM10.1199 17.4667H8.71638V16.0632H10.1199V17.4667ZM10.1199 14.473H8.71638V13.0695H10.1199V14.473ZM10.1199 11.4793H8.71638V10.0758H10.1199V11.4793Z"
				fill="#007BFF"
			/>
			<path
				d="M18.2108 14.6433C18.2108 14.6433 14.8047 18.666 14.8047 20.5471C14.8047 22.4283 16.3296 23.9532 18.2108 23.9532C20.0919 23.9532 21.6169 22.4283 21.6169 20.5471C21.6169 18.666 18.2108 14.6433 18.2108 14.6433Z"
				fill="#007BFF"
			/>
		</g>
		<defs>
			<clipPath id="clip0_1763_292">
				<rect width="24" height="24" fill="white" />
			</clipPath>
		</defs>
	</svg>
);
