import axios from "./axios";

export const createAddress = async (addressBody) => {
    try {

        const payload = {...addressBody};
        const { data } = await axios.post(`/scheduler/add/address`,  payload  );
        return data || null;
    }
    catch (e) {
        console.log(e);
    }
}