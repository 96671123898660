// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.confirm_span, .agree_span {
    font-size: 1rem;
    color: var(--desc-color);
    line-height: 1.5;
    font-weight: normal;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 0.01071em;
}

.agree_span {
    color: var(--black-color);
    padding-left: 24px;
    display: block;
}
.confirm_checkbox .agree_span{
    padding-left: 0 !important;
}
.confirm_checkbox {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding-top: 1.375rem;
    
}
.agree_checkbox {
    margin-left: 15px;
}`, "",{"version":3,"sources":["webpack://./src/Components/Description/DiagnosticFee/DiagnosticFee.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,wBAAwB;IACxB,gBAAgB;IAChB,mBAAmB;IACnB,kCAAkC;IAClC,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,cAAc;AAClB;AACA;IACI,0BAA0B;AAC9B;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,qBAAqB;;AAEzB;AACA;IACI,iBAAiB;AACrB","sourcesContent":[".confirm_span, .agree_span {\n    font-size: 1rem;\n    color: var(--desc-color);\n    line-height: 1.5;\n    font-weight: normal;\n    font-family: 'Poppins', sans-serif;\n    letter-spacing: 0.01071em;\n}\n\n.agree_span {\n    color: var(--black-color);\n    padding-left: 24px;\n    display: block;\n}\n.confirm_checkbox .agree_span{\n    padding-left: 0 !important;\n}\n.confirm_checkbox {\n    display: flex;\n    flex-direction: row;\n    align-items: flex-start;\n    padding-top: 1.375rem;\n    \n}\n.agree_checkbox {\n    margin-left: 15px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
